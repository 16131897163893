import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  condense() {
    this.element.classList.remove("condensable--expanded", "condensable--semicondensed");
    this.element.classList.add("condensable--condensed");
    this.datatablesRedraw();
  }

  semicondense() {
    this.element.classList.remove("condensable--expanded", "condensable--condensed");
    this.element.classList.add("condensable--semicondensed");
    this.datatablesRedraw();
  }

  expand() {
    this.element.classList.remove("condensable--condensed", "condensable--semicondensed");
    this.element.classList.add("condensable--expanded");
    this.datatablesRedraw();
  }

  manual() {
    this.element.classList.remove("condensable--auto");
  }

  auto() {
    this.element.classList.add("condensable--auto");
  }

  datatablesRedraw() {
    $(".js-datatables").DataTable().draw("page");
  }

  get isExpanded() {
    return this.element.classList.contains("condensable--expanded");
  }

  toggle() {
    if (this.isExpanded) {
      this.condense();
    } else {
      this.expand();
    }
  }

  connect() {
    this.element.querySelectorAll(".list-group-item--condensable").forEach((listGroupItem) => {
      listGroupItem.addEventListener("click", () => {
        listGroupItem.classList.add("list-group-item--condensable-scrollable");
      });
    });

    this.element.querySelectorAll(".condensable--condense-button").forEach((button) => {
      button.addEventListener("click", () => {
        this.manual();
        this.condense();
      });
    });
    this.element.querySelectorAll(".condensable--expand-button").forEach((button) => {
      button.addEventListener("click", () => {
        this.manual();
        this.expand();
      });
    });
    this.element.querySelectorAll(".condensable--toggle-button").forEach((button) => {
      button.addEventListener("click", () => {
        this.manual();
        this.toggle();
      });
    });
  }
}
