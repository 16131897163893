import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["display", "input"];

  connect() {
    this.path = this.data.get("path");
    if (!this.path) {
      console.warn("InputFetchDisplayController needs path");
    }
    if (!this.hasDisplayTarget) {
      console.warn("InputFetchDisplayController needs display target");
    }
    if (!this.hasInputTarget) {
      console.warn("InputFetchDisplayController needs input target");
    }
    for (let input of this.inputTargets) {
      input.addEventListener("change", (event) => {
        this.retrieveAndDisplay(input);
      });
    }
  }

  // $(document).on('turbolinks:load', function () {
  //   ClientDisplay.displayWhenBillingClientSelected("#invoice_client_id");
  //   ClientDisplay.displayWhenBillingClientSelected("#order_billing_client_id");
  // });

  retrieveAndDisplay(input) {
    this.displayTarget.innerHTML = "<i class=\"fas fa-spinner fa-spin\"></i> Loading";
    const id = input.value;
    fetch(this.path.replace("$ID", id), {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "same-origin", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "text/html",
      },
    })
      .then((response) => {
        return response.text().then((text)=>{
          this.displayTarget.innerHTML = text;
        });
      });
  }
}
