import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["presentationInput"];

  initialize() {
    this.screenSmMin = 768;
    this.selector = ".edit-one-by-one";
    this.iframeSelector = ".edit-one-by-one iframe#form";
    this.highlightClass = "highlight";
    this.eventDidChange = "editOneByOneDidChange";
    this.eventShouldVisit = "editOneByOneShouldVisit";
  }

  connect() {
    this.role = this.data.get("role"); // "parent" or "iframe"
    this.idsParam = this.data.get("idsParam");
    // https://discourse.stimulusjs.org/t/access-stimulus-controller-from-outside/78/2
    this.element[this.identifier] = this;
    if (this.role == "iframe") {
      this.setupIframe();
    } else if (this.role == "parent") {
      this.setupParent();
    } else {
      throw `EditOneByOneController not expecting role ${this.role}`;
    }
  }

  setupParent() {
    this.returnPath = this.data.get("returnPath");
    this.setupRowClicks();
    document.addEventListener(this.eventDidChange, () => {
      this.editFormDidChange();
    });
    this.iframe = document.querySelector(this.iframeSelector);
  }

  setupIframe() {
    window.parent.document.dispatchEvent(new CustomEvent(this.eventDidChange));
    document.addEventListener(this.eventShouldVisit, (event) => {
      this.shouldVisit(event.detail);
    });
  }

  setupRowClicks() {
    let rows = this.element.querySelectorAll("section.batch table tr.task[id^=task]");
    rows.forEach((elem) => {
      elem.addEventListener("click", (event) => {
        this.rowClicked(event);
      });
    });
  }

  rowClicked(event) {
    let elem = event.currentTarget;
    if (elem.classList.contains(this.highlightClass)) {
      return;
    }
    let idMatcher = /task_(\d+)/;
    let m = idMatcher.exec(elem.id);
    if (m) {
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
      let taskId = m[1];
      let visitPath = `/tasks/${taskId}/edit?${this.idsParam}`;
      this.shouldVisit(visitPath);
    }
  }

  shouldVisit(visitPath) {
    if (this.role == "parent") {
      let event = new CustomEvent(this.eventShouldVisit, {detail: visitPath});
      this.iframe.contentDocument.dispatchEvent(event);
    } else {
      Turbolinks.visit(visitPath);
    }
  }

  editFormDidChange() {
    this.highlightRow();
    this.resizeForm();
  }

  done() {
    Turbolinks.visit(this.returnPath);
  }

  changePresentation(e) {
    let label = e.currentTarget;
    let presentationInput = label.querySelector("input");
    let presentation = presentationInput.value;
    this.element.className = `edit-one-by-one ${presentation}`;
    this.resizeForm();
  }

  resizeForm() {
    if (this.element.classList.contains("bottom") || this.element.classList.contains("bottom-with-batch") || window.innerWidth < this.screenSmMin) {
      this.iframe.style.height = (this.iframe.contentWindow.document.body.scrollHeight + 10) + "px";
    } else {
      this.iframe.style.height = "";
    }
  }

  highlightRow() {
    let iframeWindow = this.iframe.contentWindow;
    if (!iframeWindow) {
      // sometimes gets called in an anomalous state
      return;
    }
    let m = new RegExp("/tasks/(\\d+)/edit").exec(iframeWindow.location.pathname);
    if (m) {
      let taskId = m[1];
      this.element.querySelectorAll(".batch tr.highlight").forEach((elem) => {
        elem.classList.remove(this.highlightClass);
      });
      this.element.querySelector(`tr#task_${taskId}`).classList.add(this.highlightClass);
    }
  }
}
