import ContactController from "./contact_controller.js";

export default class extends ContactController {
  static targets = ["ordering"];

  connect() {
    this.namePrefix = this.data.get("namePrefix");
    this.index = this.data.get("index");
    this.baseName = `${this.namePrefix}[${this.index}]`;
    super.connect();
  }

  _initializeSearch() {
    this._createRoleHiddenField();
    this._tweakOrderingHiddenField();
    if (this.select) {
      // if it's not a new form, it won't have a select
      this.select.setAttribute("name", this._nameForField("contact_id"));
      super._initializeSearch();
    }
  }

  toggleDeleted(event) {
    event.preventDefault();
    this.element.classList.toggle("sales-contact-item--deleted");
    const input = this.element.querySelector(`input[name='${this._nameForField("_destroy")}']`);
    if(input) {
      this.element.removeChild(input);
    } else {
      this.createHiddenField({fieldName: "_destroy", value: "true"});
    }
  }

  _nameForField(fieldName) {
    return `${this.baseName}[${fieldName}]`;
  }

  _createRoleHiddenField() {
    this.createHiddenField({fieldName: "role", value: this.data.get("role")});
  }
  _tweakOrderingHiddenField() {
    this.orderingTarget.setAttribute("name", this._nameForField("ordering"));
    this.orderingTarget.setAttribute("value", this.data.get("ordering"));
  }

  createHiddenField({fieldName, value}) {
    const input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", this._nameForField(fieldName));
    input.setAttribute("value", value);
    this.element.append(input);
  }
}
