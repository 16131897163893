import ApplicationController from "./application_controller";
import { useWindowResize } from "stimulus-use";

export default class extends ApplicationController {
  connect() {
    useWindowResize(this);
    this.resize();

    $(".gantt-row .progress-duration, .gantt-row .buffer-usage").tooltip();
  }

  resize() {
    var $table = $(".gantt-wrapper table");
    $(".gantt-row.task").each(function () {
      var $this = $(this);
      var ident = "#task_" + $this.data("task-id");
      var $row = $table.find(ident);
      $this.css("top", $row.position().top);
    });
  }
  windowResize() {
    this.resize();
  }
}
