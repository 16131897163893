import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    toe: String
  };
  connect() {
    // console.log({at: "racetrack-milestone", value: this.toeValue});
    this.element.addEventListener("mouseover", () => {
      this.rollover(true);
    });
    this.element.addEventListener("mouseout", () => {
      this.rollover(false);
    });
    this.application.getControllerForElementAndIdentifier(this.element.closest("[data-controller=racetrack]"), "racetrack").registerMilestone(this);
  }

  // @param {Boolean} highlighting
  highlight(highlighting) {
    if (highlighting) {
      this.element.classList.add("racetrack-milestone--highlight");
    }else {
      this.element.classList.remove("racetrack-milestone--highlight");
    }
  }

  rollover(rolledOver) {
    if (rolledOver) {
      this.element.classList.add("racetrack-milestone--rolledover");
    }else {
      this.element.classList.remove("racetrack-milestone--rolledover");
    }
  }

}
