import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["table", "toolbar"];

  initialize() {
    this.buttonNoteTypeAttrName = `data-${this.identifier}-note-type`;
    this.ifNoteTypeAttrName = `data-${this.identifier}-if-note-type`;
  }

  connect() {
    this.buttons = this.toolbarTarget.querySelectorAll(`button[${this.buttonNoteTypeAttrName}]`);
    let noteType = undefined;
    this.buttons.forEach((el) => {
      el.addEventListener("click", () => {
        this.buttonPressed(el);
      });
      if (el.classList.contains("active")){
        noteType = el.getAttribute(this.buttonNoteTypeAttrName);
      }
    });
    this.hasFetchedRows = true; // TODO: check if not yet fetched, and if has a path to fetch for.

    if (document.location.hash && document.location.hash === this.data.get("hash")) {
      this.adjustForNoteType("all");
    } else if (noteType !== undefined) {
      this.adjustForNoteType(noteType);
    }
  }

  buttonPressed(button) {
    const noteType = button.getAttribute(this.buttonNoteTypeAttrName);
    this.adjustForNoteType(noteType);
  }

  adjustForNoteType(noteType) {
    this.adjustButtons(noteType);
    if (this.hasFetchedRows) {
      this.showRowsForNoteType(noteType);
    }
    // else if (noteType !== "none") { // TODO: fetched using path...
    //   fetch()
    //     .then(response => response.text())
    //     .then((html) => {
    //       this.hasFetchRows = true;
    //       // insert html
    //       this.showRowsForNoteType(noteType);
    //     });
    // }
  }

  adjustButtons(noteType) {
    this.buttons.forEach((el) => {
      if (el.getAttribute(this.buttonNoteTypeAttrName) === noteType) {
        el.classList.add("active");
      } else {
        el.classList.remove("active");
      }
    });
  }

  showRowsForNoteType(noteType) {
    if (noteType === "all") {
      this.tableTarget.querySelectorAll(`[${this.ifNoteTypeAttrName}]`).forEach((el) => {
        el.style.display = "";
      });
    } else if (noteType === "none") {
      this.tableTarget.querySelectorAll(`[${this.ifNoteTypeAttrName}]`).forEach((el) => {
        el.style.display = "none";
      });
    } else {
      this.tableTarget.querySelectorAll(`[${this.ifNoteTypeAttrName}]`).forEach((el) => {
        if (el.getAttribute(this.ifNoteTypeAttrName) === noteType) {
          el.style.display = "";
        } else {
          el.style.display = "none";
        }
      });
    }
  }

  // function getPressedFilters() {
  //   var shown = $('.job-history-filters button.btn.filter.active').map(function () {
  //     return $(this).data('type');
  //   }).get();
  //   return shown;
  // }
  //
  // function getShownFilters() {
  //   var shown = getPressedFilters();
  //   if (shown.length === 0) {
  //     shown = $('.job-history-filters button.btn.filter').map(function () {
  //       return $(this).data('type');
  //     }).get();
  //   }
  //   return shown;
  // }
  //
  // function jobHistoryFilter() {
  //   var shown = getShownFilters();
  //   $('table.job-history tbody tr').each(function (index, row) {
  //     var $row = $(row);
  //     var rowType = $row.data('job-history-type');
  //     if ($.inArray(rowType, shown) > -1) {
  //       $row.show();
  //     } else {
  //       $row.hide();
  //     }
  //   });
  // }
  //
  // $('.job-history-filters .btn.all').click(function (e) {
  //   var $this = $(this);
  //   var wasActive = $this.hasClass('active');
  //   if (wasActive) {
  //     return;
  //   }
  //   $('.job-history-filters .btn.active').removeClass('active');
  //   $this.addClass('active');
  //   jobHistoryFilter();
  // });
  //
  // $('.job-history-filters .btn.filter').click(function (e) {
  //   var $this = $(this);
  //   var wasActive = $this.hasClass('active');
  //   if (wasActive) {
  //     $this.removeClass('active');
  //     if (getPressedFilters().length === 0) {
  //       $('.job-history-filters .btn.all').addClass('active');
  //     }
  //   } else {
  //     if (e.shiftKey !== true) {
  //       $('.job-history-filters .btn.active').removeClass('active');
  //     } else {
  //       $('.job-history-filters .btn.all').removeClass('active');
  //     }
  //     $this.addClass('active');
  //   }
  //   jobHistoryFilter();
  // });
  //

}
