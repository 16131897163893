import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static outlets = [ "clickable-rows" ];

  highlightOriginAndDependencies($origin, $deps, $nonDeps, on) {
    if (on) {
      if ($origin.hasClass("deps")) {
        $origin.addClass("highlight");
      }
      $deps.addClass("dependency");
      $nonDeps.removeClass("dependency");
    } else {
      if ($origin.hasClass("deps")) {
        $origin.removeClass("highlight");
      }
      $deps.removeClass("dependency");
      $nonDeps.removeClass("dependency");
    }
  }

  highlightSuccessors($successors, on) {
    if (on) {
      $successors.addClass("successor");
    } else {
      $successors.removeClass("successor");
    }
  }

  highlightHoveredDependencyInEditDependenciesForm(origin, on) {
    var $origin = $(origin);
    var $checkbox = $origin.find("input");
    var ident = ".task-label-" + $checkbox.attr("data-from-task") + "-description";
    var $cells = $origin.closest("table").find(ident);
    if (on) {
      $cells.addClass("possible-dependency");
    } else {
      $cells.removeClass("possible-dependency");
    }
  }

  highlightSuccessorsInEditDependenciesForm($row, on) {
    var mynum = parseInt($row.children(":first").text().trim(), 10);
    var $table = $row.closest("table");
    var $origins = $table.find("tr td.task-dep-from-" + mynum);
    $table.find("td.successor").removeClass("successor");
    if ($origins.length < 1) {
      return;
    }
    var $successors = $();
    $origins.each(function (i, target) {
      var $target = $(target);
      var $checkbox = $target.find("input");
      if ($checkbox.is(":checked") || $checkbox.hasClass("checked")) {
        $successors = $successors.add($target.closest("tr").children(":first"));
        $checkbox.closest("td").addClass("successor");
      }
    });
    this.highlightSuccessors($successors, on);
  }

  highlightDependenciesInEditDependenciesForm(row, on) {
    var $row = $(row);
    var $origins = $row.find("td.dep");
    if ($origins.length < 1) {
      return;
    }
    var $table = $row.closest("table");
    var $deps = $();
    var $nonDeps = $();
    $origins.each(function (i, target) {
      var $checkbox = $(target).find("input");
      var ident = ".task-label-" + $checkbox.attr("data-from-task");
      if ($checkbox.is(":checked") || $checkbox.hasClass("checked")) {
        $deps = $deps.add(ident, $table);
      } else {
        $nonDeps = $nonDeps.add(ident, $table);
      }
    });
    this.highlightOriginAndDependencies($row.find("td"), $deps, $nonDeps, on);
    this.highlightSuccessorsInEditDependenciesForm($row, on);
  }

  highlightDependenciesInJobsTaskTable(row, on) {
    const $origin = $(row).find("td.deps");
    if ($origin.length !== 1) {
      this.highlightDependenciesInEditDependenciesForm(row, on);
      return;
    }
    if ($origin.text() == "(deleted)") {
      return;
    }
    const $table = $origin.closest("table");
    let $deps = $();
    $.each($origin.text().split(","), function (i, value) {
      var ident = ".task-label-" + value.trim();
      $deps = $deps.add(ident, $table);
    });
    this.highlightOriginAndDependencies($origin, $deps, $(), on);
    const mylabel = $origin.closest("tr").children(":first").text().trim();
    let $successors = $();
    $table.find("td.deps").each(function () {
      let $this = $(this);
      $.each($this.text().split(","), function (i, value) {
        if (value.trim() === mylabel) {
          $successors = $successors.add($this.closest("tr").children(":first")[0]);
        }
      });
    });
    this.highlightSuccessors($successors, on);
  }


  connect() {
    // mode which works on clicks rather than rollovers
    this.stickyMode = false;

    if (this.hasClickableRowsOutlet) {
      this.clickableRowsOutlet.setRowClickedCallback((clickedTr) => {
        this.clickableRowClicked(clickedTr);
      });
    } else{
      console.warn("Highlight dependencies can't do sticky because doesn't have clickable rows controller(surprising)");
    }

    // should be job tasks / task templates view only, but maybe edit dependencies too?
    this.element.querySelectorAll("tr.task, tr.task_template").forEach((elem) => {
      elem.addEventListener("mouseenter", () => {
        if (this.stickyMode) {
          return;
        }
        this.highlightDependenciesInJobsTaskTable(elem, true);
      });
      elem.addEventListener("mouseleave", () => {
        if (this.stickyMode) {
          return;
        }
        this.highlightDependenciesInJobsTaskTable(elem, false);
      });
    });

    // should be edit dependencies only
    this.element.querySelectorAll("td.dep").forEach((elem) => {
      elem.addEventListener("mouseenter", () => {
        elem.classList.add("possible-dependency");
        this.highlightHoveredDependencyInEditDependenciesForm(elem, true);
      });
      elem.addEventListener("mouseleave", () => {
        elem.classList.remove("possible-dependency");
        this.highlightHoveredDependencyInEditDependenciesForm(elem, false);
      });
    });

    // should be edit dependencies only
    this.element.querySelectorAll("td.dep input").forEach((elem) => {
      elem.addEventListener("change", () => {
        this.highlightDependenciesInEditDependenciesForm(elem.closest("tr"), true);
      });
    });
  }

  clickableRowClicked(clickedTr) {
    this.element.querySelectorAll("tr.task, tr.task_template").forEach((elem) => {
      if (elem == clickedTr ) {
        if (this.stickyMode != clickedTr.id) {
          this.stickyMode = clickedTr.id;
        } else {
          this.stickyMode = false;
        }
      }
    });

    // this (next 10 lines) could be more elegant
    this.element.querySelectorAll("tr.task, tr.task_template").forEach((elem) => {
      if ( elem != clickedTr) {
        this.highlightDependenciesInJobsTaskTable(elem, false);
      }
    });
    this.element.querySelectorAll("tr.task, tr.task_template").forEach((elem) => {
      if ( elem == clickedTr) {
        this.highlightDependenciesInJobsTaskTable(elem, true);
      }
    });

  }
}
