import ApplicationController from "./application_controller";
import Sortable from "sortablejs";

export default class extends ApplicationController {
  static targets = ["deleteButton", "hiddenField", "fileField", "img", "bwsImageRadio", "topImageRadio"];

  // static targets = ["progress", "progressBar"];

  connect() {
    if (this.element.parentNode.firstElementChild == this.element) {
      // TODO: should really define the sortability on the parent.
      new Sortable(this.element.parentNode, {
        onChoose: () => this.element.parentNode.querySelectorAll(".associated-image--pastezone").forEach((element) =>element.classList.remove("associated-image--pastezone")),
        onEnd: ()=>this.adjustReorderingAll(),
        filter: ".associated-image--top-image"});
    }
    if (this.hasFileFieldTarget) {
      this._setupFileUpload();
      this._setupPasteZoneClicks();
    }
    if (this.hasDeleteButtonTarget) {
      this.deleteButtonTarget.addEventListener("click", (event) => {
        event.preventDefault();
        this.markAsDeleted();
      });
    }
    if (this.hasTopImageRadioTarget) {
      this._setupTopImageClick();
    }
  }

  teardown() {
    document.removeEventListener("click", this.clickedOutsideOfThisElementListener);
  }

  startingUpload() {
    this.element.classList.remove("associated-image--new-without-img");
  }

  finishedUpload() {

  }

  _setupTopImageClick() {
    this.topImageRadioTarget.addEventListener("input", () => {
      this.element.parentNode.querySelectorAll(".associated-image--top-image")
        .forEach(element => element.classList.remove("associated-image--top-image"));
      if (this.hasFileFieldTarget) {
        this.element.classList.add("associated-image--top-image");
        setTimeout(() => {
          this.element.parentNode.prepend(this.element);
          this.adjustReorderingAll();
        }, 100); // 200 ms
      }
    });
  }

  _setupPasteZoneClicks() {
    this.clickedOutsideOfThisElementListener = (event) => {
      let node = event.target;
      while (node && node != document.body) {
        if (node == this.element) {
          return;
        }
        node = node.parentNode;
      }
      this.removePasteZoneClass();
    };
    document.addEventListener("click", this.clickedOutsideOfThisElementListener);
    this.element.addEventListener("click", (event) => {
      let node = event.target;
      while (node != this.element) {
        // non-pasteable - they grab clicks.
        if (["label", "select", "img", "a"].includes(node.nodeName.toLowerCase())) {
          this.removePasteZoneClass();
          return;
        } else if (node.nodeName.toLowerCase() == "input" && node.getAttribute("type")!= "text") {
          // input[type=text] works fine so we don't skip it
          this.removePasteZoneClass();
          return;
        }
        node = node.parentNode;
      }


      const textInput = this.element;
      if (textInput) {
        this.addPasteZoneClass();
        textInput.querySelector("input[type=text]").focus();
      } else {
        console.warn("paste won't work reliably because of lack of text input");
      }
    });
  }

  addPasteZoneClass() {
    this.element.classList.add("associated-image--pastezone");
  }

  removePasteZoneClass() {
    this.element.classList.remove("associated-image--pastezone");
  }

  _setupFileUpload() {
    const controllerInstance = this;
    // TODO: remove usage of antique jquery plugin and convert to using direct_upload_controller.js (with image display, and paste and drop support)
    var fileInput = $(this.fileFieldTarget);
    var form = $(fileInput.parents("form:first"));
    var submitButton = form.find("input[type=\"submit\"]");
    var progressBar = $("<div class='progress-bar'></div>");
    var barContainer = $("<div class='progress'></div>").css("display", "none").append(progressBar);
    fileInput.after(barContainer);
    fileInput.fileupload({
      fileInput: fileInput,
      url: form.data("url"),
      type: "POST",
      dropZone: fileInput.closest(".image-dropzone"),
      pasteZone: fileInput.closest(".image-dropzone"),
      autoUpload: true,
      formData: form.data("form-data"),
      paramName: "file", // S3 does not like nested name fields i.e. name="user[avatar_url]"
      dataType: "XML",  // S3 returns XML if success_action_status is set to 201
      replaceFileInput: false,
      progressall: function (e, data) {
        var progress = parseInt(data.loaded / data.total * 100, 10);
        progressBar.css("width", progress + "%");
      },
      submit: function (e, data) {
        var nonImage = $.grep(data.files, function (f) {
          return !f.type.startsWith("image");
        }).length > 0;
        if (nonImage) {
          progressBar.css("background", "red").text("Can only upload images");
          return false;
        }
        return true;
      },
      start: function (e) {
        controllerInstance.startingUpload();
        submitButton.prop("disabled", true);
        barContainer.css("display", "block");
        progressBar.css("background", "green").css("display", "block").css("width", "0%").text("Loading...");
      },
      done: function (e, data) {
        controllerInstance.finishedUpload();
        submitButton.prop("disabled", false);
        progressBar.text("Uploading done");

        // extract key and generate URL from response
        var key = $(data.jqXHR.responseXML).find("Key").text();
        var url = "//" + form.data("host") + "/" + encodeURIComponent(key);

        // set value of hidden field
        var input = $("input[type=hidden][name=\"" + fileInput.attr("name") + "\"]");
        input.val(url);
        // make the image also have that url
        var img = $("img[data-forInput=\"" + fileInput.attr("name") + "\"]");
        img.attr("src", url);
      },
      fail: function (e, data) {
        submitButton.prop("disabled", false);

        progressBar.css("background", "red").text("Failed");
      }
    });
  }

  markAsDeleted() {
    this.hiddenFieldTarget.value = window.BwsConfig.imageUrlRemove;
    this.imgTarget.setAttribute("src", window.BwsConfig.missingImageSquareUrl);
    this.deleteButtonTarget.style.display = "none";
    if (this.hasBwsImageRadioTarget) {
      this.bwsImageRadioTarget.checked = false;
    }
    if (this.hasTopImageRadioTarget) {
      this.topImageRadioTarget.checked = false;
    }
  }

  // NB this is used out of context of this controller - maybe should be in separate sortable controller
  adjustReorderingAll() {
    const parentNode = this.element.parentNode;

    // first, make sure the topImage is at the top
    for (const child of parentNode.children) {
      const topImageField = child.querySelector("input[data-associated-image-target=topImageRadio]");
      if (topImageField && topImageField.checked) {
        parentNode.prepend(child);
      }
    }
    let ordering = 1;
    for (const child of parentNode.children) {
      const orderingField = child.querySelector("input[data-associated-image-target=orderingField]");
      if (orderingField) {
        orderingField.setAttribute("value", ordering);
        ordering += 1;
      }
    }
  }
}
