import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["hiddenField", "fileField", "progress", "progressBar"];

  connect() {
    this.parentForm = this.element.closest("form");
    this.fileFieldTarget.addEventListener("input", () => {
      this.fileFieldChanged();
    });
    if (this.hiddenFieldTarget.value) {
      this.hideFileField();
      this.updateProgress(1);
    }
  }

  fileFieldChanged() {
    this.hideFileField();
    this.showProgressAndCancel();
    this.upload();
  }

  upload() {
    const file = this.fileFieldTarget.files[0];
    const url = this.parentForm.dataset.url;
    const formData = new FormData();
    const parsed = JSON.parse(this.parentForm.dataset.formData);
    for (let key in parsed) {
      formData.append(key, parsed[key]);
    }
    // Actual file has to be appended last.
    formData.append("file", file);

    // vaguely based on
    // https://www.webiny.com/blog/upload-files-to-aws-s3-using-pre-signed-post-data-and-a-lambda-function-7a9fb06d56c1
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.send(formData);
    xhr.addEventListener("progress", (event) => {
      this.updateProgress(event.loaded / event.total);
    });
    xhr.addEventListener("load", (event) => {
      this.updateProgress(event.loaded / event.total);
      if (xhr.status === 201) {
        let uploadedUrl = this.demungeS3Url(xhr.responseXML.querySelector("Location").textContent);
        this.uploadSuccessful(uploadedUrl);
      } else {
        this.uploadUnsuccessful(xhr);
      }
    });
  }

  uploadSuccessful(uploadedUrl) {
    this.hiddenFieldTarget.value = uploadedUrl;
    this.progressBarTarget.classList.add("progress-bar-success");
  }

  uploadUnsuccessful(xhr) {
    console.error(`upload unsuccessful ${xhr.status}: ${xhr.responseText}`);
    this.progressBarTarget.classList.add("progress-bar-danger");
  }

  hideFileField() {
    this.fileFieldTarget.style.display = "none";
  }

  showProgressAndCancel() {
    this.progressTarget.style.display = "";
    this.updateProgress(0);
  }

  updateProgress(fraction) {
    this.progressTarget.style.display = "";
    this.progressBarTarget.style.width = `${fraction * 100}%`;
    if (fraction == 1) {
      this.progressBarTarget.innerText = "Completed Uploading";
    } else {
      this.progressBarTarget.innerText = `Uploading (${(fraction * 100).toFixed()}%)`;
    }
  }
}
