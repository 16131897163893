class JsHostForIosApp {
  /* iOS will pass in a callbackHandler and it's useful when testing from the console to pass in the dummy:
   * window.Jshost.getCredentials(window.Jshost.dummyCallbackHandler());
   *
   * This callbackHandler will either receive {bearer_token: "value", user_id: "value", etc} or it will receive {error: "error message", status: 4xx}
  * */
  getCredentials(callbackHandler, deviceName) {
    if (!callbackHandler || !deviceName) {
      console.error("You need to supply (callbackHandler, deviceName) -- FYI deviceName is scoped to user.");
      return false;
    }
    let currentUserIdElem = document.querySelector("[data-jshost-current-user-id]");
    if(!currentUserIdElem) {
      callbackHandler({
        error: "Can't determine if already logged in",
        status: "500"
      });
      return false;
    } else if(!currentUserIdElem.dataset["jshostCurrentUserId"]) {
      callbackHandler({
        error: "Not logged in",
        status: 401
      });
      return false;
    }
    let xhr = new XMLHttpRequest();
    xhr.open("POST", "/user_devices", true);
    xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
    let csrfElement = document.querySelector("meta[name=\"csrf-token\"]");
    xhr.send(JSON.stringify({authenticity_token: csrfElement.content, device_name: deviceName}));

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 400) {
        let json = JSON.parse(xhr.response);
        if (json.error) {
          callbackHandler({"error": `${json.error} (${xhr.status})`, status: xhr.status});
        } else {
          callbackHandler({"bearer_token": json.token, "user_id": json.user_id, "user_initials": json.user_initials, "user_email": json.user_email});
        }
      } else {
        callbackHandler({
          "error": `Unexpected response status (${xhr.status}). Detail: ${xhr.response}`,
          status: xhr.status
        });
      }
    };

    xhr.onerror = () => {
      callbackHandler({error: "big unexpected error", status: 0});
    };

    return true;
  }

  dummyCallbackHandler() {
    return (payload) => {
      console.warn("Dummy received callback: ");
      console.warn(payload);
    };
  }

  QRCodeScanned(qrCodeUrl) {
    console.info(`QRCodeScanned: ${qrCodeUrl}`); // eslint-disable-line no-console
    if (this.QRCodeScannerCallback) {
      this.QRCodeScannerCallback(qrCodeUrl);
    }
  }

  setQRCodeScannedCallback(callableOrNull) {
    this.QRCodeScannerCallback = callableOrNull;
  }

  // called back by window.webkit.messageHandlers.getAppVersion
  appVersion(versionString) {
    this.versionString = versionString;
    console.info(`versionString: ${versionString}`); // eslint-disable-line no-console
    if (this.versionStringCallback) {
      this.versionStringCallback(versionString);
    }
  }

  requestVersionString(callback) {
    this.versionStringCallback = callback;
    window.webkit.messageHandlers.getAppVersion.postMessage("getAppVersion");
  }
}

module.exports = new JsHostForIosApp();

