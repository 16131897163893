import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["template", "list", "addButtonRow", "ordering"];

  connect() {
  }

  new(event) {
    event.preventDefault();
    const clone = document.importNode(this.templateTarget.content, true);
    const index = this._nextIndex();
    const ordering = this._nextOrdering();
    clone.firstElementChild.setAttribute("data-sales-contact-index", index);
    // clone.firstElementChild.dataset.salesContactIndex = index;
    clone.firstElementChild.setAttribute("data-sales-contact-ordering", ordering);
    // clone.firstElementChild.dataset.salesContactOrdering = ordering;
    this.listTarget.insertBefore(clone, this.addButtonRowTarget);
  }

  cancelNew(event) {
    event.preventDefault();
    let t = event.currentTarget;
    while (t.parentNode != this.listTarget) {
      t = t.parentNode;
    }
    this.listTarget.removeChild(t);
  }

  _nextIndex() {
    let t = this.element;
    while ("salesContactsCount" in t.dataset === false) {
      t = t.parentNode;
    }
    const nextIndex = parseInt(t.dataset.salesContactsCount) + 1;
    t.dataset.salesContactsCount = nextIndex;
    return nextIndex;
  }
  _nextOrdering() {
    let maxOrdering = this.orderingTargets.map((o) => {
      return o.value;
    }).reduce((max, value) => {
      return Math.max(max, value);
    }, 0);
    return maxOrdering + 1;
  }

}
