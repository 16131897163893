import ApplicationController from "./application_controller";

const Choices = require("choices.js");
const SingleSelectDynamicChoices = require("../single_select_dynamic_choices.js");

export default class extends ApplicationController {
  connect() {
    this.select = this.hasSelectTarget ? this.selectTarget : this.element.querySelectorAll("select")[0];
    if (!this.select) {
      console.warn("No select found. This contact controller can't be used for new");
    } else {
      this.__connectForNew();
    }
  }

  __connectForNew() {
    this.clientId = this.data.get("clientId");
    this.baseSearchUrl = this.data.get("searchUrl");
    if(!this.clientId || !this.baseSearchUrl) {
      console.error(`ContactController missing parameter ${!this.clientId ? "client-id" : "" } ${!this.baseSearchUrl ? "search-url" : "" }`);
      return;
    }
    this._initializeSearch();
  }

  _initializeSearch() {
    if(!this.select) {
      return;
    }
    this.choices = new Choices(this.select, {
      allowHTML: true,
      noChoicesText: "No matches",
      searchPlaceholderValue: "Start typing name or email of a contact",
      shouldSort: false,
      searchFloor: 0,
      searchChoices: false
      // searchResultLimit: 7
    });
    this.choicesWrapper = new SingleSelectDynamicChoices(this.select, this.choices, {
      searchFunction: (query) => this.search(query),
      valueKey: "id", labelKey: "display"
    });
    this.choices.showDropdown();
    this.choicesWrapper.serverLookup();
  }

  search(query, callback) {
    return fetch(`${this.baseSearchUrl}?preferred_client_id=${this.clientId}&q=${query}`, {
      // method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "same-origin", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, same-origin, *omit
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
      }
    }
    )
      .then(function (response) {
        return response.json().then((data) => {
          return data;
        });
      })
      .catch(function (error) {
        console.warn(error);
      })
    ;
  }
}
