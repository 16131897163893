// with inspiration from https://hybrd.co/posts/github-issue-style-file-uploader-using-stimulus-and-active-storage
import ApplicationController from "./application_controller";

class Upload {
  constructor(file, bwsPostController) {
    this.type = file.type;
    this.fileName = file.name;
    this.spinnerUrlValue = bwsPostController.spinnerUrlValue;
  }

  get isImage() {
    return this.type === "image/png" || this.type == "image/jpeg";
  }

  get isPDF() {
    return this.type === "application/pdf";
  }
  get placeholderMarkdown() {
    if (!this._placeholderMarkdown) {
      this._placeholderMarkdown = `![Uploading...](${this.spinnerUrlValue}#${self.crypto.randomUUID()})`;
    }
    return this._placeholderMarkdown;
  }

  replacementMarkdown(uploadedUrl) {
    if (this.isImage) {
      return `![IMAGE ${this.fileName}](${uploadedUrl})`;
    } else if(this.isPDF) {
      return `[PDF ${this.fileName}](${uploadedUrl})`;
    } else {
      return `\`${uploadedUrl}\` (**Unexpected type '${this.type}'**)`;
    }
  }
}

export default class extends ApplicationController {
  static targets = ["textarea"];
  static values = {
    spinnerUrl: String,
    uploadDataUrl: String
  };

  connect() {
    this.parentForm = this.element.closest("form");
    this.textareaTarget.addEventListener("dragenter", (event) => {
      event.dataTransfer.dropEffect = "copy";
      event.dataTransfer.effectAllowed = "copy";
      this.textareaTarget.classList.add("bwsm-post-droppable");
    });

    this.textareaTarget.addEventListener("dragleave", (event) => {
      event.dataTransfer.dropEffect = "none";
      event.dataTransfer.effectAllowed = "none";
      this.textareaTarget.classList.remove("bwsm-post-droppable");
    });

  }

  dropUpload(event) {
    event.preventDefault();
    this.textareaTarget.classList.remove("bwsm-post-droppable");
    this.uploadFiles(event.dataTransfer.files);
  }

  pasteUpload(event) {
    if (!event.clipboardData.files.length) return;

    event.preventDefault();
    this.uploadFiles(event.clipboardData.files);
  }

  selectFileAndUpload(event) {
    event.preventDefault();

    const fileSelector = document.createElement("input");
    fileSelector.setAttribute("style", "display: none;");
    fileSelector.setAttribute("type", "file");
    this.parentForm.appendChild(fileSelector);
    fileSelector.addEventListener("change", (event) => {
      this.uploadFiles(event.target.files);
      fileSelector.remove();
      // NB: it only removes itself if successful, but if cancelled it should not contain anything, so should be ok.
    });
    fileSelector.click();
  }

  uploadFiles(files) {
    this.selectionPoint = this.textareaTarget.selectionEnd;
    Array.from(files).forEach(file => {
      const upload = this.prepareUiForUploadingFile(file);
      this.uploadFile(file, upload);
    });
  }

  prepareUiForUploadingFile(file) {
    const upload = new Upload(file, this);
    const fullReplacement = `\n${upload.placeholderMarkdown}\n`;
    this.textareaTarget.setRangeText(fullReplacement, this.selectionPoint, this.selectionPoint);
    this.selectionPoint = this.selectionPoint + fullReplacement.length;
    this.textareaTarget.setSelectionRange(this.selectionPoint, this.selectionPoint);
    this.triggerTextAreaInputEvent();
    return upload;
  }

  uploadSuccessful(upload, uploadedUrl) {
    this.parentForm.classList.remove("bwsm-post-uploading");
    this.replacePlaceholder(upload, upload.replacementMarkdown(uploadedUrl));
  }

  replacePlaceholder(upload, replacementMarkdown) {
    const start = this.textareaTarget.value.indexOf(upload.placeholderMarkdown);
    this.textareaTarget.setRangeText(replacementMarkdown, start, start + upload.placeholderMarkdown.length);
    this.triggerTextAreaInputEvent();
  }

  triggerTextAreaInputEvent() {
    const event = new InputEvent("input", {view: window, bubbles: true, cancelable: true});
    this.textareaTarget.dispatchEvent(event);
  }

  uploadUnsuccessful(upload, error) {
    this.parentForm.classList.remove("bwsm-post-uploading");
    this.replacePlaceholder(upload, upload.replacementMarkdown(`**upload of '${upload.fileName}' failed**: ${error}`));
  }

  async uploadFile(file, upload) {
    this.parentForm.classList.add("bwsm-post-uploading");
    const uploadDataResponse = await fetch(this.uploadDataUrlValue, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "same-origin", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!uploadDataResponse.ok) {
      const text = await uploadDataResponse.text();
      this.uploadUnsuccessful(file, upload, {
        message: `could not get upload data: ${text}`
      });
    }
    const uploadData = await uploadDataResponse.json();

    // const url = this.parentForm.dataset.url;
    const formData = new FormData();
    for (let key in uploadData["form-data"]) {
      formData.append(key, uploadData["form-data"][key]);
    }
    // Actual file has to be appended last.
    formData.append("file", file);

    // vaguely based on
    // https://www.webiny.com/blog/upload-files-to-aws-s3-using-pre-signed-post-data-and-a-lambda-function-7a9fb06d56c1
    const xhr = new XMLHttpRequest();
    xhr.open("POST", uploadData.url, true);
    xhr.send(formData);
    xhr.addEventListener("progress", (event) => {
      this.updateProgress(event.loaded / event.total);
    });
    xhr.addEventListener("load", (event) => {
      this.updateProgress(event.loaded / event.total);
      if (xhr.status === 201) {
        let s3Url = xhr.responseXML.querySelector("Location").textContent;
        let uploadedUrl = this.demungeS3Url(s3Url);
        this.uploadSuccessful(upload, uploadedUrl);
      } else {
        this.uploadUnsuccessful(upload, {message: xhr.responseText, xhr: xhr});
      }
    });
  }

  updateProgress(fraction) {
    // this.progressTarget.style.display = "";
    // this.progressBarTarget.style.width = `${fraction * 100}%`;
    // if (fraction == 1) {
    //   this.progressBarTarget.innerText = "Completed Uploading";
    // } else {
    //   this.progressBarTarget.innerText = `Uploading (${(fraction * 100).toFixed()}%)`;
    // }
  }

}
