import ApplicationController from "./application_controller";
export default class extends ApplicationController {
  static targets = ["hidden", "hidable"];

  connect() {
  }
  remove(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    this.hiddenTarget.value = "";
    this.hidableTargets.forEach(element=>{ element.remove();});
  }
}
