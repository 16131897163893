import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  connect() {
    if(window.webkit) {
      window.webkit.messageHandlers.hasLoggedOut.postMessage(true);
    } else {
      console.info("BWS logged out (non-app)."); // eslint-disable-line no-console
    }
  }
}
