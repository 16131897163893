import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["count", "log"];

  connect() {
    this.log("connect");
    let count = this.data.get("unreadCount");
    if (count === "0" || count) {
      this.setUnreadCount(count);
    }
  }
  disconnect() {
    this.log("disconnect");
  }

  setUnreadCountFromInput() {
    let count = this.countTarget.value;
    this.setUnreadCount(count);
  }

  setUnreadCount(count) {
    this.log(`setUnreadCount.postMessage(${count})`);
    if (window.webkit) {
      window.webkit.messageHandlers.setUnreadCount.postMessage(count);
    } else {
      this.log("no window.webkit found", "asError");
    }
  }

  log(message, asError) {
    if (asError) {
      console.error(`AppMessages: ${message}`);
    } else {
      console.log(`AppMessages: ${message}`); // eslint-disable-line no-console
    }
    if(this.hasLogTarget){
      this.logTarget.innerHTML = `${this.logTarget.innerHTML}${message}\n`;
    }
  }
}
