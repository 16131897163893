import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  connect() {
    if(window.Jshost.versionString) {
      this.displayVersionString(window.Jshost.versionString);
    } else if(window.webkit) {
      window.Jshost.requestVersionString((versionString) => this.displayVersionString(versionString));
    } else {
      this.displayVersionString("non-app");
      console.info("No Version String (non-app)."); // eslint-disable-line no-console
    }
  }

  displayVersionString(versionString) {
    this.element.innerText = versionString;
  }
}
