import ApplicationController from "./application_controller";

/*
  allows a input to become an autosubmit
 */
export default class extends ApplicationController {
  static targets = ["control", "hidable"];

  connect() {
    if (this.hasHidableTarget) {
      $(this.hidableTarget).hide();
    }
    for (let control of this.controlTargets) {
      control.addEventListener("change", (event) => {
        this.controlChanged(event.target);
      });
    }
  }

  controlChanged(control) {
    let form = $(control).closest("form");
    $(form).find(":submit").attr("disabled", true).addClass("submit--submitting");
    form.submit();
  }
}
