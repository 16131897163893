import ApplicationController from "./application_controller";
// import dialogPolyfill from "dialog-polyfill";

export default class extends ApplicationController {
  static targets = ["link", "editable", "editor"];

  connect() {
    this.href = this._findHref();
    this.linkTarget.addEventListener("click", (event) => {
      this.showPackageFromEvent(event);
    });
  }

  showPackageFromEvent(event) {
    if (event) {
      // this controller is sometimes double added (some bad interaction with datatables), so to prevent weirdness, we just do this once and prevent propagation.
      event.stopImmediatePropagation();
      event.preventDefault();
    }
    this.showPackage();
  }

  showPackage() {
    Turbolinks.visit(this.href);
    // this.displayDialog();
  }

  //
  // displayDialog() {
  //   this.dialog = this._createDialog();
  //   this.editor = this._createEditor();
  //   this.dialog.querySelector("form [data-js-form-dialog--body]").innerHTML = ''; // clear in case already used
  //   this.dialog.querySelector("form [data-js-form-dialog--body]").appendChild(this.editor);
  //   this._adjustDialog();
  //
  //   this.eventListener = () => {
  //     this.dialogClosed();
  //   };
  //   this.dialog.addEventListener('close', this.eventListener);
  //   this.dialog.showModal();
  // }
  //
  // _adjustDialog() {
  //   //place cursor at beginning and add return;
  //   this.editor.value = "\n\n" + this.editor.value;
  //   this.editor.setSelectionRange(0, 0);
  // }
  //
  // dialogClosed() {
  //   this.dialog.removeEventListener('close', this.eventListener);
  //   if (this.dialog.returnValue !== "submit") {
  //     return;
  //   }
  //   // console.log(`submit ${this.path}`);
  //   const csrfToken = $("meta[name='csrf-token']").attr('content');
  //   const data = {job_current_note: {text: this.editor.value}, _method: 'put', authenticity_token: csrfToken};
  //   fetch(this.path, {
  //     method: "PUT", // *GET, POST, PUT, DELETE, etc.
  //     mode: "same-origin", // no-cors, cors, *same-origin
  //     cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  //     credentials: "same-origin", // include, *same-origin, omit
  //     headers: {
  //       "Content-Type": "application/json",
  //       // "Content-Type": "application/x-www-form-urlencoded",
  //     },
  //     body: JSON.stringify(data)
  //   })
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((json) => {
  //       this.element.outerHTML = json.partial;
  //     });
  // }
  //
  //
  // _createDialog() {
  //   let dialog = document.querySelector('[data-js-form-dialog]');
  //   dialogPolyfill.registerDialog(dialog);
  //   const submit = dialog.querySelector("button[value=submit]");
  //   if (submit) submit.innerHTML = "Save";
  //   return dialog;
  // }
  //
  // // some reference to https://johnbeatty.co/2018/04/11/lets-make-an-editable-header-using-stimulus-js/
  //
  // _createEditor() {
  //   let editor;
  //   if (this.hasEditorTarget) {
  //     editor = this.editorTarget.cloneNode(true);
  //     editor.classList.remove("dialog-editable--hidden");
  //   } else {
  //     editor = document.createElement("textarea");
  //   }
  //   return editor;
  // }

  _findHref() {
    if (!this.hasLinkTarget) {
      console.warn("no link target found for package_row_controller");
      return null;
    }
    if (this.linkTarget.hasAttribute("href")) {
      return this.linkTarget.getAttribute("href");
    }
    let a = this.linkTarget.querySelector("a[href]");
    if (a) {
      return a.getAttribute("href");
    }
  }
}
