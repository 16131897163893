import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    url: String,
    target: String
  };

  connect() {
  }

  async replace() {
    const response = await fetch(this.urlValue, {
      headers: {
        // shows rails we're using XHR
        "X-Requested-With": "XMLHttpRequest"
      },
    });
    const html = await response.text();
    document.querySelector(this.targetValue).outerHTML = html;
  }
}
