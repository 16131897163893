import ApplicationController from "./application_controller";

const Choices = require("choices.js");
const SingleSelectDynamicChoices = require("../single_select_dynamic_choices.js");

// Takes a select element and wraps it in a search
// the search url should take a q parameter
// and return a json object with an array of choices, each of which
// has an id and a display
export default class extends ApplicationController {
  static targets = ["select"];
  static values = {
    url: String // url to use for search - must take a q parameter
  };

  connect() {
    this.select = this.hasSelectTarget ? this.selectTarget : this.element.querySelectorAll("select")[0];
    this.choices = new Choices(this.select, {
      allowHTML: true,
      noChoicesText: "No matches",
      searchPlaceholderValue: "Start typing...",
      shouldSort: false,
      searchFloor: 2,
      searchChoices: false
      // searchResultLimit: 7
    });
    this.choicesWrapper = new SingleSelectDynamicChoices(this.select, this.choices, {
      searchFunction: (query) => this.search(query),
      valueKey: "id", labelKey: "display"
    });
  }

  search(query, callback) {
    let url = new URL(this.urlValue);
    url.searchParams.append("q", query);
    return fetch(url, {
      // method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "same-origin", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, same-origin, *omit
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
      }
    }
    )
      .then( (response) => {
        return response.json().then((data) => {
          return data;
        });
      })
      .catch((error) => {
        console.warn(error);
      })
    ;
  }
}
