import ApplicationController from "./application_controller";

// NB every page that uses this needs to turn preview off (to allow for judderless refresh)
// <meta name="turbolinks-cache-control" content="no-preview">
// or use disable_turbolinks_preview_cache before rendering <head>

export default class extends ApplicationController {
  initialize() {
    this.scrollPosition = null;
    document.addEventListener("turbolinks:load", () => {
      if (this.scrollPosition) {
        setTimeout(() => {
          window.scrollTo.apply(window, this.scrollPosition);
          this.scrollPosition = null;
        });
      }
    });
  }
  connect() {
    this.timeout = setTimeout(() => {
      this.refresh();
    }, 5 * 1000); // 5 seconds
  }

  teardown() {
    clearTimeout(this.timeout);
  }

  refresh() {
    // loosely based on
    // https://coderwall.com/p/ii0a_g/page-reload-refresh-every-5-sec-using-turbolinks-js-rails-jquery
    // https://stackoverflow.com/questions/37358652/how-to-refresh-a-page-with-turbolinks/44353026#44353026

    this.scrollPosition = [window.scrollX, window.scrollY];
    Turbolinks.visit(window.location.toString(), { action: "replace" });
  }
}
