import ApplicationController from "./application_controller";

class MessageHandler {
  constructor(handlerName) {
    this.handlerName = handlerName;
  }

  postMessage(message) {
    this.checkAndLog(message);
  }

  checkAndLog(message) {
    if (message == undefined) {
      console.error("when calling postMessage must specify a message");
      return false;
    } else {
      console.warn(`window.webkit[debug].messageHandlers.${this.handlerName}.postMessage(${message});`); // using warn rather than info so that it picks up with default selenium / chrome logging config
      return true;
    }
  }
}

class GetAppVersionMessageHandler extends MessageHandler {
  postMessage(message) {
    if (this.checkAndLog(message)) {
      window.Jshost.appVersion("vDEV");
    }
  }
}

export default class extends ApplicationController {
  connect() {
    if (!window.webkit) {
      this.messageHandlers = {};
      [
        "hasLoggedOut",
        "showQRCodeScanner",
        "openUrl",
        "setUnreadCount",
        "startOnedriveUpload"
      ].forEach(messageHandler => {
        this.messageHandlers[messageHandler] = new MessageHandler(messageHandler);
      });
      this.messageHandlers.getAppVersion = new GetAppVersionMessageHandler("getAppVersion");
      window.webkit = this;
    }
  }

  disconnect() {
    if (window.webkit && window.webkit == this) {
      window.webkit = null;
    }
  }
}
