import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    // https://github.com/turbolinks/turbolinks/issues/272#issuecomment-615038967
    for (let form of document.querySelectorAll("form[method=get]:not([data-remote=true])")) {
      form.addEventListener("submit", (submitEvent)=> {
        submitEvent.preventDefault();
        const entries = [...new FormData(submitEvent.target).entries()];
        const actionUrl = new URL(submitEvent.target.action);
        const currentUrl = new URL(location.href);
        // if pathname not changed, hand over per parameter to next page.
        if (actionUrl.pathname === currentUrl.pathname && currentUrl.searchParams.has("per")) {
          actionUrl.searchParams.set("per", currentUrl.searchParams.get("per"));
        }
        entries.forEach(entry => actionUrl.searchParams.append(...entry));
        Turbolinks.visit(actionUrl.toString());
      });
    }
  }
}
