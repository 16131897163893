// very loosely based on https://github.com/jshjohnson/Choices/issues/162
// but for single select

class SingleSelectDynamicChoices {
  constructor(elem, choices, {searchFunction, lookupDelay = 200, valueKey = "id", labelKey = "name"}) {
    this.choices = choices;
    this.searchFunction = searchFunction;
    // new Choices(elem, config);
    this.lookupDelay = lookupDelay;
    this.valueKey = valueKey;
    this.labelKey = labelKey;

    // Some config and bookkeeping for API calls.
    this.lookupTimeout = null;
    this.lookupCache = {};

    // Trigger an API lookup when the user pauses after typing.
    elem.addEventListener("search", (event) => {
      clearTimeout(this.lookupTimeout);
      this.lookupTimeout = setTimeout(() => {
        this.serverLookup();
      }, this.lookupDelay);
    });

    // We want to clear the API-provided options when a choice is selected.
    elem.addEventListener("choice", (event) => {
      this.choices.setChoices([], this.valueKey, this.labelKey, true);
    });
  }

  // Function to perform the API lookup.
  serverLookup() {
    // we can't use choices.currentValue because this is blank if we set searchChoices
    // to false. Instead we use the actual input field's value.
    const query = this.choices.input.value;
    if (query in this.lookupCache) {
      // empty will still be cached
      this.populateOptions(this.lookupCache[query]);
    } else {
      // Ajax query and results parsing depends on your API structure and how
      // you make AJAX calls in your codebase.
      let p = this.searchFunction(query);
      p.then((results) => {
        this.lookupCache[query] = results;
        this.populateOptions(results);
      });
    }
  }

  // Function to actually populate the results from the API lookup.
  populateOptions(results) {
    this.choices.setChoices(results, this.valueKey, this.labelKey, true);
  }

}

// can't use export default class for some reason (https://github.com/webpack/webpack/issues/3929)
module.exports = SingleSelectDynamicChoices; /* */
