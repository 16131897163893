import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["table"];
  static values = {
    focusSearch: {type: Boolean, default: true}
  };

  initialize() {
    // set up default configs for all datatables
    if (!$.fn || !$.fn.dataTable) {
      console.log("dataTable not loaded on initialize"); // eslint-disable-line no-console
      return;
    }
    // console.log("initialize from DatatablesController");

    $.extend($.fn.dataTable.defaults, {
      "columnDefs": [
        {"targets": "js-datatables-nosort", "orderable": false},
        {"targets": "js-datatables-numeric", "type": "num-fmt"}
      ],
      "language": {
        "search": "Filter (this page):"
      },
      "paging": false,
      "search": false,
      "stateDuration": 60 * 10,
      "stateSave": window.BwsConfig.rememberLastSort
    });

  }

  connect() {
    if (!$.fn || !$.fn.dataTable) {
      console.error("dataTable not loaded on connect");
      return;
    }
    let $tableTarget = $(this.tableTarget);
    // console.log("connect from DatatablesController");
    if (!$tableTarget.attr("id")) {
      console.warn("DatatablesController warning: statesaving won't work on this table");
    }

    this.dataTable = $tableTarget.DataTable(this.makeConfig($tableTarget));

    let searchInput = this.element.querySelector(".dataTables_filter input");
    if (searchInput) {
      if (this.focusSearchValue) {
        searchInput.focus();
      }
      searchInput.addEventListener("keyup", (event) => {
        this.autoExpandCondense(event);
      });
    }
  }

  autoExpandCondense(event) {
    console.warn("autoExpandCondense");
    var autoCondensable = this.tableTarget.closest("[data-controller=condensable].condensable--auto");
    console.warn({autoCondensable: autoCondensable});
    if (autoCondensable) {
      const condensableController = this.application.getControllerForElementAndIdentifier(autoCondensable, "condensable");
      if (event.target.value === "") {
        condensableController.condense();
      } else {
        condensableController.expand();
      }
    }
  }

  // useful for testing bug: https://datatables.net/forums/discussion/71499/possible-bug-in-destroy-with-fixedheader#latest
  testTeardown(event) {
    event.preventDefault();
    event.stopPropagation();
    this.teardown();
  }

  // https://www.betterstimulus.com/turbolinks/teardown.html
  teardown() {
    if (this.dataTable !== null) {
      // remove the datatables add-ons
      this.dataTable.destroy();
      this.dataTable = null;
    }
  }

  makeConfig($tableTarget) {
    // apply custom configs to each datatable
    let config = {};
    if ($tableTarget.hasClass("js-datatables-no-search")) {
      config.filter = false;
    }
    let $defaultAsc = $tableTarget.find("thead tr th.js-datatables-default-sort-asc").index();
    if ($defaultAsc > -1) {
      config.order = [
        [$defaultAsc, "asc"]
      ];
    }
    if ($tableTarget.hasClass("js-datatables-noinitialsort")) {
      config.order = [];
    }
    if ($tableTarget.hasClass("js-datatables-nosort")) {
      config.ordering = false;
    }
    if ($tableTarget.data("datatables-fixed-columns")) {
      config.fixedColumns = {leftColumns: $tableTarget.data("datatables-fixed-columns")};
      config.scrollX = true;
      const unscrollableTop = Math.max(...Array.from(document.querySelectorAll("table thead")).map(e => e.getBoundingClientRect().bottom));
      // const navbarHeight = document.querySelector(".navbar-fixed-top").offsetHeight;
      const footerHeight = document.querySelector("#footer").offsetHeight;
      const datatableAutoFooterHeight = 30; // approximation "Showing x to y of z entries"
      const saveButton = 30; // approximation "Save" button
      const extraDelta = 50; //random extra bit just in case;
      const remainHeight = window.innerHeight - unscrollableTop - footerHeight - datatableAutoFooterHeight - saveButton - extraDelta;
      const percentHeight = Math.floor(remainHeight * 100 / window.innerHeight);
      // console.warn({
      //   a1_windowHeight: $(window).height(),
      //   a2_windowInnerHeight: window.innerHeight,
      //   a3_$navbarHeight : $(".navbar-fixed-top").outerHeight(),
      //   a4_footerHeight : footerHeight,
      //   a5_remainHeight: remainHeight,
      //   a6_percent : percentHeight,
      //   a7_unscrollableTop: unscrollableTop,
      //   a8_navbarHeight: navbarHeight,
      //   a9_extraDelta: extraDelta
      // });
      config.scrollY = `${percentHeight}vh`;
      // if (window.innerHeight > 700) {
      //   config.scrollY = "60vh";
      // } else {
      //   config.scrollY = "40vh";
      // }

    } else {
      config.fixedHeader = {
        header: true,
        headerOffset: $(".navbar-fixed-top").outerHeight()
      };
    }
    return config;
  }
}
