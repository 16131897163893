import AppQrCodeScannerController from "./app_qr_code_scanner_controller";

export default class extends AppQrCodeScannerController {
  static targets = ["url", "log", "scanned"];

  connect() {
    super.connect();
    this.setupCallback();
    this.showScanner(true);
  }

  qrCodeScanned(url) {
    super.qrCodeScanned(url);
    if(this.hasScannedTarget) {
      this.scannedTarget.click();
    }
  }
}
