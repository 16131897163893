import ApplicationController from "./application_controller";

const VERTICAL_KEY = "bwsRacetrackVertical"; // localStorage key
const VERTICAL_NORMAL_VALUE = "normal";
const VERTICAL_CONDENSED_VALUE = "condensed";

export default class extends ApplicationController {
  static targets = [ "toeButton", "verticalCondensedButton", "verticalNormalButton"];

  connect() {
    // console.log("boom");
    this.racetrackElement = this.element.querySelector(".racetrack"); // could make this better with a target...
    this.milestones = [];
    this.highlightedToe = null;
    this.verticalNormalButtonTarget.addEventListener("click", (event) => {
      event.preventDefault();
      this.vertical = VERTICAL_NORMAL_VALUE;
      this.verticalNormal();
    });
    this.verticalCondensedButtonTarget.addEventListener("click", (event) => {
      event.preventDefault();
      this.vertical = VERTICAL_CONDENSED_VALUE;
      this.verticalCondensed();
    });
    if (this.vertical == VERTICAL_CONDENSED_VALUE) {
      this.verticalCondensed();
    } else {
      this.verticalNormal();
    }
  }


  disconnect() {
    // drop references
    this.milestones = [];
  }

  registerMilestone(milestone) {
    // console.log("registerMilestone", milestone);
    this.milestones.push(milestone);
    // console.log({at: "registerMilestone()", milestones: this.milestones.length});
  }

  toggleToe(event) {
    event.preventDefault();
    let toe = event.params.toe;
    let date = event.params.date;
    let highlighting = false;
    if (this.highlightedToe == toe) {
      highlighting = false;
      this.highlightedToe = null;
    } else {
      highlighting = true;
      this.highlightedToe = toe;
    }
    this.highlight(toe, highlighting);
    this.highlightDate(date, highlighting);
  }

  // @param {String} toe
  // @param {Boolean} highlighting
  highlight(toe, highlighting) {
    this.milestones.forEach((milestone) => {
      if (milestone.toeValue == toe) {
        milestone.highlight(highlighting);
      } else if (highlighting) {
        milestone.highlight(false);
      }
    });

    this.toeButtonTargets.forEach(target => {
      if (toe == target.dataset.racetrackToeParam && highlighting) {
        target.classList.add("active");
      } else {
        target.classList.remove("active");
      }
    });
  }

  highlightDate(date, highlighting) {
    this.element.querySelectorAll("[data-racetrack-grid-day]").forEach((elem) => {
      if (highlighting && elem.dataset.racetrackGridDay == date ) {
        elem.classList.add("racetrack-grid--day--highlight");
      } else {
        elem.classList.remove("racetrack-grid--day--highlight");
      }
    });
  }

  verticalCondensed() {
    this.verticalNormalButtonTarget.classList.remove("active");
    this.verticalCondensedButtonTarget.classList.add("active");
    this.racetrackElement.classList.add("racetrack--vertical-condensed");
    this.racetrackElement.classList.remove("racetrack--vertical-normal");
    document.documentElement.style.setProperty("--racetrack-row-height", "25px");
  }
  verticalNormal() {
    this.verticalNormalButtonTarget.classList.add("active");
    this.verticalCondensedButtonTarget.classList.remove("active");
    this.racetrackElement.classList.remove("racetrack--vertical-condensed");
    this.racetrackElement.classList.add("racetrack--vertical-normal");
    document.documentElement.style.setProperty("--racetrack-row-height", "55px");
  }

  get vertical() {
    return localStorage.getItem(VERTICAL_KEY) || undefined;
  }
  set vertical(value) {
    localStorage.setItem(VERTICAL_KEY, value);
  }
}
