import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["form"];

  connect() {
    this.element.querySelector("input[type=file]").addEventListener("change", ()=>{
      this.formTarget.submit();
    });
  }

  reveal(event) {
    event.currentTarget.style.display = "none";
    this.formTarget.style.display = "";
    this.element.querySelector("input[type=file]").click();
  }
}
