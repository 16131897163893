import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["url", "log"];

  connect() {
    // this.log("AppQrCodeScannerController connect");
  }
  disconnect() {
    // this.log("AppQrCodeScannerController disconnect");
    this.unsetCallback();
  }

  scan() {
    this.setupCallback();
    this.showScanner(true);
  }

  showScanner(show) {
    this.log(`showQRCodeScanner.postMessage(${show})`);
    if (window.webkit) {
      window.webkit.messageHandlers.showQRCodeScanner.postMessage(show);
    } else {
      this.log("no window.webkit found", "asWarning");
    }
  }

  setupCallback() {
    if (window.Jshost) {
      window.Jshost.setQRCodeScannedCallback((url) => this.qrCodeScanned(url));
    }
  }
  unsetCallback() {
    if (window.Jshost) {
      window.Jshost.setQRCodeScannedCallback(null);
    }
  }
  qrCodeScanned(url){
    this.log(`I have been called back with ${url}`);
    if(this.hasUrlTarget) {
      this.urlTarget.value = url;
    }
    this.unsetCallback();
    this.showScanner(false);
  }
  log(message, asWarning) {
    if (asWarning) {
      console.warn(`AppQrCodeScannerController: ${message}`);
    } else {
      console.log(`AppQrCodeScannerController: ${message}`); // eslint-disable-line no-console
    }
    if(this.hasLogTarget){
      this.logTarget.innerHTML = `${this.logTarget.innerHTML}${message}\n`;
    }
  }
}
