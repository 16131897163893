import ApplicationController from "./application_controller";
const debounce = require("../debounce.js");

export default class extends ApplicationController {
  static targets = ["input", "preview"];

  connect() {
    if (!(this.hasInputTarget && this.hasPreviewTarget)) {
      console.error("markdown controller should have input and preview targets");
      return;
    }
    let prevValue = null;
    this.onChange = debounce(() => {
      if (prevValue !== this.inputTarget.value) {
        this.updatePreview();
        prevValue = this.inputTarget.value;
      }
    }, 200, false);

    this.inputTarget.addEventListener("input", this.onChange, false);
    if(this.inputTarget.value.trim() !== "") {
      this.onChange();
    }
    this.requestId = 0;
  }

  teardown() {
    clearTimeout(this.timeout);
  }

  updatePreview() {
    this.requestId++;
    const requestId = this.requestId;
    const request = new XMLHttpRequest();
    request.open("POST", this.previewUrl, /* async */ true);
    request.setRequestHeader("Content-Type", "text/plain");
    request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    request.onload = () => {
      if (
        // Ignore server errors
        request.status >= 200 && request.status < 400 &&
        // Ignore older responses received out-of-order
        requestId === this.requestId) {
        this.previewTarget.innerHTML = request.responseText;
      }
    };
    request.send(this.inputTarget.value);
  }

  get previewUrl() {
    return "/markdown/preview";
  }
}
