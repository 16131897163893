import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    html: Boolean,
    position: String,
    container: String,
    trigger: String
  };

  connect() {
    $(this.element).tooltip(this.tooltipOptions);
  }

  get tooltipOptions() {
    const options = {container: this.containerValue || "body"};
    if (this.htmlValue) {
      options.html = "true";
    }
    if (this.positionValue) {
      options.position = this.positionValue;
    }
    if (this.triggerValue) {
      options.trigger = this.triggerValue;
    }
    return options;
  }
}
