import ApplicationController from "./application_controller";
import dialogPolyfill from "dialog-polyfill";

export default class extends ApplicationController {
  static targets = ["editable", "editor"];
  static values = {
    /* The model name as param key */
    modelParamKey: String,
    /* The attr name as param key */
    paramKey: String,
    /* the path for update - must return a json with a partial: key*/
    path: String
  };


  connect() {
  }

  disconnect() {
    this.dialog = undefined;
  }

  displayDialog(event) {
    event.preventDefault();
    this.dialog = this._createDialog();
    this.editor = this._createEditor();
    this.dialog.querySelector("form [data-js-form-dialog--body]").innerHTML = ""; // clear in case already used
    this.dialog.querySelector("form [data-js-form-dialog--body]").appendChild(this.editor);
    this._adjustDialog();

    this.eventListener = () => {
      this.dialogClosed();
    };
    this.dialog.addEventListener("close", this.eventListener);
    this.editor.addEventListener("keydown", (e) => {
      if(e.keyCode == 13 && e.metaKey) {
        this.submit();
        this.dialog.close();
      }
    });

    this.dialog.showModal();
  }

  _adjustDialog() {
    //place cursor at beginning and add return;
    this.editor.value = "\n\n" + this.editor.value;
    this.editor.setSelectionRange(0, 0);
  }

  dialogClosed() {
    this.dialog.removeEventListener("close", this.eventListener);
    if (this.dialog.returnValue !== "submit") {
      return;
    }
    this.submit();
  }


  submit() {
    const csrfToken = $("meta[name='csrf-token']").attr("content");
    const data = {_method: "put", authenticity_token: csrfToken};
    data[this.modelParamKeyValue] = {};
    data[this.modelParamKeyValue][this.paramKeyValue] = this.editor.value;
    fetch(this.pathValue, {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      mode: "same-origin", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify(data)
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        this.element.outerHTML = json.partial;
      });
  }

  _createDialog() {
    let dialog = document.querySelector("[data-js-form-dialog]");
    dialogPolyfill.registerDialog(dialog);
    const submit = dialog.querySelector("button[value=submit]");
    if (submit) {
      const isMac = navigator.userAgent.indexOf("Mac OS X") != -1;
      const meta = isMac ? "\u2318" : "\u2303";
      const enter = "\u23CE" ;
      submit.innerHTML = `Save &nbsp;<small>${meta}${enter}</small>`;
    }
    return dialog;
  }

  // some reference to https://johnbeatty.co/2018/04/11/lets-make-an-editable-header-using-stimulus-js/

  _createEditor() {
    let editor;
    if (this.hasEditorTarget) {
      editor = this.editorTarget.cloneNode(true);
      editor.classList.remove("dialog-editable--hidden");
    } else {
      editor = document.createElement("textarea");
    }
    return editor;
  }
}
