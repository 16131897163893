import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["log", "objectUri"];

  connect() {
    // this.log("connect");
  }
  disconnect() {
    // this.log("disconnect");
  }

  startOnedriveUpload() {
    let objectUri = this.objectUriTarget.value;
    this.log(`startOnedriveUpload.postMessage(${objectUri})`);
    if (window.webkit){
      window.webkit.messageHandlers.startOnedriveUpload.postMessage(objectUri);
    } else {
      this.log("no window.webkit found", "asError");
    }
  }

  log(message, asError) {
    if (asError) {
      console.error(`AppUpload: ${message}`);
    } else {
      console.log(`AppUpload: ${message}`); // eslint-disable-line no-console
    }
    if(this.hasLogTarget){
      this.logTarget.innerHTML = `${this.logTarget.innerHTML}${message}\n`;
    }
  }
}
