import ApplicationController from "./application_controller";

const EVEN = "tr--package-row--job-even";
const ODD = "tr--package-row--job-odd";

export default class extends ApplicationController {
  static targets = ["jobSortableHeader"];

  connect() {
    setTimeout(() => {
      if (window.DataTable.isDataTable(this.table)) {
        let dataTable = window.jQuery(this.table).DataTable();
        // could also use event listening or callback see https://datatables.net/reference/event/draw
        dataTable.on("draw", () => {
          this.checkSort();
        });
      }
      this.checkSort();
    }, 100);
  }
  checkSort() {
    let isSorted = (target) => (target.classList.contains("sorting_asc") || target.classList.contains("sorting_desc"));
    let sortedByJob = this.jobSortableHeaderTargets.some(isSorted);
    this.addJobAlternationClasses(sortedByJob);
  }

  addJobAlternationClasses(add) {
    let lastJobId = null;
    let jobClass = this.toggleJobClass(null);
    this.table.querySelectorAll("tbody tr").forEach((tr) => {
      tr.classList.remove(ODD, EVEN);
      if (add) {
        if (lastJobId !== tr.dataset.jobId) {
          lastJobId = tr.dataset.jobId;
          jobClass = this.toggleJobClass(jobClass);
        }
        tr.classList.add(jobClass);
      }
    });
  }

  toggleJobClass(currentClass) {
    if (currentClass == EVEN) {
      return ODD;
    } else {
      return EVEN;
    }
  }

  get table() {
    return this.element;
  }
}
